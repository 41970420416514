<template>
<div>
  <loading-overlay :active="loadingGeneral" :is-full-page="true" loader="bars" />
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <CRow>
        <CCol sm="10" class="d-flex mt-1">{{collapseTitle}}</CCol>
        <CCol sm="2">
          <CButton color="edit" size="sm" class="float-right" @click="tablaCollapse = !tablaCollapse" v-c-tooltip="{content: $t('label.dataTable')}" >
            {{ cambiaTexto }}
            <CIcon :name="cambiaIcono"/>
          </CButton>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="10">
          <CRow>
            <CCol sm="12"> <!--:is-valid="TpUnidSelectColor"-->
                <CSelect
                    :label="$t('label.measureUnitType')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-model="$v.formUnidadMedida.IdTpUnidadMedida.$model" 
                    :value.sync="formUnidadMedida.IdTpUnidadMedida"
                    :invalid-feedback="errorMessage($v.formUnidadMedida.IdTpUnidadMedida)"
                    :is-valid="hasError($v.formUnidadMedida.IdTpUnidadMedida)"
                    :options="TpUnitMeasureOptions"
                />
            </CCol>
            <CCol sm="12">
                <CInput
                    :label="$t('label.acronym')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    :is-valid="hasError($v.formUnidadMedida.SiglasUnidadMedida)"
                    :invalid-feedback="errorMessage($v.formUnidadMedida.SiglasUnidadMedida)"
                    v-uppercase
                    :placeholder="$t('label.acronym')"
                    v-model="$v.formUnidadMedida.SiglasUnidadMedida.$model" 
                    required 
                >
                
                  <!--  <template #invalid-feedback>
                        <div v-if="$v.formUnidadMedida.NbUnidadMedida.$error">
                            <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.required">{{$t('label.required')}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.onlyMayusText">{{$t('label.onlyUppercase')}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.minLength">{{$t('label.min')}} {{$v.formUnidadMedida.NbUnidadMedida.$params.minLength.min}} {{$t('label.chars')}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.maxLength">{{$t('label.max')}} {{$v.formUnidadMedida.NbUnidadMedida.$params.maxLength.max}} {{$t('label.chars')}}</div>
                        </div>
                    </template> -->
                </CInput>
            </CCol>
            <CCol sm="12">
                <CInput
                    :label="$t('label.measureUnit')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-uppercase
                    v-model="$v.formUnidadMedida.NbUnidadMedida.$model" 
                    maxlength="30"
                    :placeholder="$t('label.name')"
                    :invalid-feedback="errorMessage($v.formUnidadMedida.NbUnidadMedida)"
                    :is-valid="hasError($v.formUnidadMedida.NbUnidadMedida)"
                    required 
                >
                 <!--    <template #invalid-feedback>
                        <div v-if="$v.formUnidadMedida.SiglasUnidadMedida.$error">
                            <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.required">{{$t('label.required')}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.siglasAlphanumeric">{{$t('label.onlyUppercase')+' '+$t('label.and')+' '+$t('label.number')+'s'}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.minLength">{{$t('label.min')}} {{$v.formUnidadMedida.SiglasUnidadMedida.$params.minLength.min}} {{$t('label.chars')}}</div>
                            <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.maxLength">{{$t('label.max')}} {{$v.formUnidadMedida.SiglasUnidadMedida.$params.maxLength.max}} {{$t('label.chars')}}</div>
                        </div>
                    </template> -->
                </CInput>
            </CCol>
            <CCol sm="12" v-if="actualizar">
                <CSelect
                    :label="$t('label.status')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-model="Status"
                    :value.sync="Status"
                    :is-valid="statusSelectColor"
                    :options="selectOptions"
                />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="2" class="text-end">
          <CButton
            size="sm"
            color="add"
            v-c-tooltip="{content: $t('label.add')}"
            class="mr-1"
            v-if="actualizar"
            @click="evaluaStatus"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt" />
          </CButton>
          <CButton
            size="sm"
            color="add"
            v-c-tooltip="{content: $t('label.add')}"
            class="mr-1"
            v-if="!actualizar"
            @click="evaluaStatus"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt" />
          </CButton>
          <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields')}" @click="limpiarDatos" >
            <CIcon name="cil-brush-alt"/>
          </CButton>
        </CCol>
      </CRow>
      <CCollapse :show="tablaCollapse" class="mt-2">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="items"
          :fields="fields"
          :loading="loadingTable"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          hover
          small
          sorter
          pagination
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #Status="{item}">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Detalle="{item}">
          <td class="py-2 text-center">
            <CButton color="edit" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.measureUnit')}" @click="editDatos(item)" >
              <CIcon name="pencil" />
            </CButton>
            <!--<CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.delete')+' '+$t('label.measureUnit')}" @click="ConfirmarEliminado(item)" >
              <CIcon name="cil-trash" />
            </CButton>-->
          </td>
        </template>
        </dataTableExtended>
      </CCollapse>
    </CCardBody>
  </CCard>
</div>
</template>

<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import { FormUnidadMedida } from '@/_validations/unidadmedida/UnidadValidations';
import General from '@/_mixins/general';
import { mapState } from 'vuex';

//Data
function data() {
  return {
    items: [],
    TpUnitMeasureOptions: [],
    isSubmit: false,
    muestraTabla: false,
    loadingGeneral: false,
    loadingTable: false,
    tablaCollapse: false,
    actualizar: false,
    iconoCollapse: 'cil-chevron-top-alt',
    Status: 1,
 
    formUnidadMedida:{
        IdUnidadMedida:0,
        NbUnidadMedida:'',
        SiglasUnidadMedida:'',
        FgActUnidadMedida:true,
        IdTpUnidadMedida:''
    },
  }
}

function fields(){
  return [
      { key: 'Nro', label: '#',_style: 'width:1%; text-align:center;', filter: false  },
      { key: 'UnitMeasureName',label: this.$t('label.MeasurementUnit'), _style:'width:20%' },
      { key: 'TpUnitMeasureName',label: this.$t('label.type'), _style:'width:20%',  },
      { key: 'UnitMeasureAbbrev',label: this.$t('label.acronym'), _style:'width:10%;',  },
      { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width: 10%;'},
      { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;'},
      { key: 'Status', label: this.$t('label.status'), _style:'width:10%; text-align:center;'},
      { 
          key: 'Detalle', 
          label: '', 
          sorter: false, 
          filter: false,
          _style:'min-width:45px; width:2%; text-align:center;'
      }
  ];
}

function limpiarDatos() {
    this.formUnidadMedida.IdUnidadMedida=0;
    this.formUnidadMedida.NbUnidadMedida='';
    this.formUnidadMedida.SiglasUnidadMedida='';
    this.formUnidadMedida.FgActUnidadMedida=true;
    this.formUnidadMedida.IdTpUnidadMedida='';
    this.actualizar = false;
    this.$nextTick(() => { this.$v.$reset() })
}

function TpUnidSelectColor(){
    return this.formUnidadMedida.IdTpUnidadMedida !== "";
}

function editDatos(item) {
    this.formUnidadMedida.IdUnidadMedida = item.UnitMeasureId;
    this.formUnidadMedida.Status = item.Status;
    this.formUnidadMedida.NbUnidadMedida = item.UnitMeasureName;
    this.formUnidadMedida.IdTpUnidadMedida = item.TpUnitMeasureId;
    this.formUnidadMedida.SiglasUnidadMedida = item.UnitMeasureAbbrev;
    this.formUnidadMedida.FgActUnidadMedida = true;
    this.actualizar = true;
    this.$v.formUnidadMedida.$touch();
}

function cerrar() {
    this.$emit('child-refresh',true)
}

async function listarTpUnitMeasure () {
  this.TpUnitMeasureOptions = [];
  this.loadingOverlay = true;
  
  let listado = Array;
  await this.$http.get("TpUnitMeasure-list", { filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data].filter(ob => ob.Status==="ACTIVO" );
    this.TpUnitMeasureOptions = listado.map(listado => Object.assign({}, this.TpUnitMeasureOptions, {
      value: listado.TpUnitMeasureId,
      label: listado.TpUnitMeasureName,
    }));

    this.TpUnitMeasureOptions.push({
      value: "",
      label: this.$t("label.select"),
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.loadingOverlay = false;
  });
}
 
 async function listarUnitMeasure () {
  this.items = [];
  this.loadingGeneral = true;
  
  let listado = Array;
  await this.$http.get("UnitMeasure-list", { filter: 'ALL' })
    .then(response => {
        listado = [...response.data.data];
        this.items = listado.map(listado => Object.assign({}, this.items, {
            Nro: listado.Nro,
            UnitMeasureId: listado.UnitMeasureId,
            UnitMeasureName: listado.UnitMeasureName, 
            TpUnitMeasureName: listado.TpUnitMeasureName, 
            TpUnitMeasureId: listado.TpUnitMeasureId, 
            UnitMeasureAbbrev: listado.UnitMeasureAbbrev, 
            UltimoUsuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
            FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
            Status: listado.Status,
            _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
        }));
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    })
    .then(() => {
        this.loadingGeneral = false;
    });
}

function collapseTitle() {
  return this.actualizar ? this.$t('label.edit')+' '+this.$t('label.measureUnit')+': '+this.formUnidadMedida.NbUnidadMedida : this.$t('label.nuevo')+' '+this.$t('label.measureUnit');
}
function cambiaIcono() {
  return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}
function selectOptions(){
  return [
      { 
          value: 1, 
          label: this.$t('label.ACTIVO')
      },
      { 
          value: 0, 
          label: this.$t('label.INACTIVO')
      }
  ];
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(
      alertPropertiesHelpers(this,{
        text: `${this.$t('label.changeStatusQuestion')} ${this.formUnidadMedida.NbUnidadMedida}`
      })
      ).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}
  
function guardar () {
  try{
    this.loadingGeneral = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.loadingGeneral = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let UnitMeasureJson    = [];
    let metodo      = '';
    let ruta        = '';
    let res         = [];

    if (this.actualizar) {
      UnitMeasureJson = {
        UnitMeasureId: this.formUnidadMedida.IdUnidadMedida,
        TpUnitMeasureId: this.formUnidadMedida.IdTpUnidadMedida,
        UnitMeasureName: this.formUnidadMedida.NbUnidadMedida,
        UnitMeasureAbbrev: this.formUnidadMedida.SiglasUnidadMedida,
        Status: this.Status,
        UserId: this.user.UserId
      };
      metodo = 'PUT';
      ruta = "UnitMeasure-update";
    } else {
      UnitMeasureJson = {
        TpUnitMeasureId: this.formUnidadMedida.IdTpUnidadMedida,
        UnitMeasureName: this.formUnidadMedida.NbUnidadMedida,
        UnitMeasureAbbrev: this.formUnidadMedida.SiglasUnidadMedida,
        UserId: this.user.UserId,
      };
      metodo = 'POST';
      ruta = "UnitMeasure-insert";
    }

    this.$http.ejecutar(metodo, ruta, UnitMeasureJson, { root: 'UnitMeasureJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: "success"
      });
      this.isSubmit = false;
      this.loadingGeneral= false;
      this.limpiarDatos();
      this.listarTpUnitMeasure();
      this.listarUnitMeasure();
    }).catch((err) => {
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingGeneral= false;
    });
  }catch(e){
    this.isSubmit = false;
    this.loadingGeneral= false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

/*function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this,{
        text: `${this.$t('label.changeStatusQuestion')} ${item.UnitMeasureName}`
      })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingGeneral = true;
      let res = [];
      let UnitMeasureJson = [];

      UnitMeasureJson = {
        UnitMeasureId: item.UnitMeasureId,
        TpUnitMeasureId: item.TpUnitMeasureId,
        UnitMeasureName: item.UnitMeasureName,
        UnitMeasureAbbrev: item.UnitMeasureAbbrev,
        Status: 0,
        UserId: this.user.UserId
      };

      this.$http.put("UnitMeasure-update", UnitMeasureJson, { root: 'UnitMeasureJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: "success"
        });
        this.limpiarDatos();
        this.listarTpUnitMeasure();
        this.listarUnitMeasure();
        this.loadingGeneral= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingGeneral= false;
      });
    }
  });  
}*/

export default {
  name: 'add-insumo',
  mixins: [General],
  data,
  directives: UpperCase,
  validations(){ return FormUnidadMedida() },
  props: ['value'],
  methods: {
      listarTpUnitMeasure,
      listarUnitMeasure,
      editDatos,
      limpiarDatos,
      cerrar,
      //ConfirmarEliminado,
      guardar,
      evaluaStatus,
  },
  computed: {
    collapseTitle,
    cambiaIcono,
    cambiaTexto,
    TpUnidSelectColor,
    selectOptions,
    fields,
    ...mapState({
        user: state => state.auth.user,
    })
  },
  watch: {
  
  },
  mounted () {
    if (this.value) {
        //this.setLineaData(this.value);
    }
    this.listarTpUnitMeasure();
    this.listarUnitMeasure();
  },
}
</script>
<style lang="scss" scope>
.center-cell {
  text-align: center;
}
 
</style>